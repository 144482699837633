.immortal {
    color: #e2a40b;
}

.regular-rum {
    color: #4A90E2;
}

.rum-list-item {
    border-bottom: 1px solid white;
}